import styled from 'styled-components';

import { Heading } from 'src/modules/text';
import { TextInput } from 'src/modules/input';
import { colors, breakpoints } from 'src/styles';

export default {
  HeadingContainer: styled.div`
    margin-bottom: 25px;
  `,
  Heading: styled(Heading)`
    text-align: center;
    margin-bottom: 15px;
  `,
  SubHeading: styled.div`
    text-align: center;
    max-width: 490px;
    margin: 0 auto;
  `,
  InputHint: styled.div`
    width: 80%;
    text-align: right;
    text-transform: uppercase;
    color: ${colors.main.grey2};
    font-weight: bold;
    font-size: 14px;
    margin: 0 auto;
  `,
  InputContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  TextInput: styled(TextInput)`
    width: 100%;

    @media only screen and (min-width: ${breakpoints.xs.max}) {
      width: 80%;
    }
  `,
  ButtonContainer: styled.div`
    height: 60px;
    display: flex;
    justify-content: center;
    margin-top: 25px;
  `,
  SuccessContact: styled.div`
    font-weight: bold;
    color: ${colors.main.green};
  `,
  ErrorContact: styled.div`
    font-weight: bold;
    color: ${colors.main.orange};
  `,
  ReCaptchaInfo: styled.div`
    font-size: 12px;
    text-align: center;
    margin-bottom: 12px;
  `,
};
