import React from 'react';
import PropTypes from 'prop-types';

import { FluidImage } from 'src/modules/image';
import { Section, PageWrap } from 'src/modules/layout';
import S from './index.WhoSection.Styled';

const WhoSection = ({ heading, logos }) => (
  <Section withPadding>
    <PageWrap>
      <S.Heading>{heading}</S.Heading>
      <S.LogoList>
        {logos.map(logo => (
          <S.LogoContainer key={logo.alt} style={{ width: logo.width }}>
            <FluidImage image={logo.image} alt={logo.alt} />
          </S.LogoContainer>
        ))}
      </S.LogoList>
    </PageWrap>
  </Section>
);

WhoSection.propTypes = {
  heading: PropTypes.string.isRequired,
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object,
      alt: PropTypes.string,
    }),
  ).isRequired,
};

export default WhoSection;
