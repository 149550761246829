import styled from 'styled-components';

import { Heading } from 'src/modules/text';
import { colors, breakpoints } from 'src/styles';

export default {
  HeadingContainer: styled.div`
    margin-bottom: 35px;
  `,
  Heading: styled(Heading)`
    text-align: center;
    margin-bottom: 10px;
  `,
  SubHeading: styled.div`
    text-align: center;
  `,
  ProgramList: styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  `,
  ProgramItem: styled.div`
    width: 100%;
    min-height: 180px;
    background-color: white;
    margin: 16px 8px;
    padding: 20px;
    box-sizing: border-box;
    border: solid 1px ${colors.main.grey1};
    border-radius: 7px;

    @media only screen and (min-width: ${breakpoints.xs.max}) {
      width: 230px;
      min-height: 250px;
    }
  `,
  ProgramTitle: styled.div`
    color: ${colors.main.blue};
    font-size: 23px;
    font-weight: bold;
    border-bottom: 3px solid ${colors.main.orange};
    height: 60px;
  `,
  ProgramDescription: styled.div`
    margin-top: 20px;
  `,
};
