import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import IndexPageTemplate from './index/index.Template';

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <IndexPageTemplate
      headerSectionData={frontmatter.headerSectionData}
      bulletSectionData={frontmatter.bulletSectionData}
      programSectionData={frontmatter.programSectionData}
      whoSectionData={frontmatter.whoSectionData}
      contactSectionData={frontmatter.contactSectionData}
    />
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object.isRequired,
    }),
  }).isRequired,
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        headerSectionData {
          heading
          text
          link
          isLinkContactAnchor
          image {
            childImageSharp {
              fluid(maxWidth: 550) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        bulletSectionData {
          heading
          bullets {
            text
          }
        }
        programSectionData {
          heading
          subHeading
          programs {
            title
            description
          }
        }
        whoSectionData {
          heading
          logos {
            image {
              childImageSharp {
                fluid(maxWidth: 170) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            alt
            width
          }
        }
        contactSectionData {
          heading
          subHeading
        }
      }
    }
  }
`;
