import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { convertMDToInnerHTML } from 'src/modules/text';
import { FontIcon } from 'src/modules/image';
import { colors } from 'src/styles';
import { Section, PageWrap } from 'src/modules/layout';
import { Button } from 'src/modules/input';
import S from './index.ContactSection.Styled';

function onInputChangeSetState(setStateCallback) {
  return event => {
    const { value } = event.target;

    setStateCallback(value);
  };
}

function submitForm(message, name, email, onFinish = () => {}) {
  const POST_URL = 'https://7g5ghvuq76.execute-api.eu-west-1.amazonaws.com/dev/emailContactForm';

  const submitWithToken = async token => {
    const data = { name, email, message, reCaptchaClientToken: token };

    try {
      const response = await fetch(POST_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(data),
      });

      if (response.status === 200) {
        onFinish(true);
      } else {
        onFinish(false);
      }
    } catch (error) {
      onFinish(false);
    }
  };

  window.grecaptcha.execute('6LcOLM0UAAAAACQPvGef65CrMHBdOeCegCILP0Z1', { action: 'homepage' }).then(submitWithToken);
}

const ContactSection = ({ heading, subHeading }) => {
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('pending');

  const handleSubmitFinish = success => {
    if (success) setStatus('success');
    else setStatus('error');
  };

  return (
    <Section withPadding backgroundColor={colors.background.lightblue}>
      <PageWrap>
        <S.HeadingContainer>
          <div id="contact" />
          <S.Heading>{heading}</S.Heading>
          <S.SubHeading dangerouslySetInnerHTML={convertMDToInnerHTML(subHeading)}></S.SubHeading>
        </S.HeadingContainer>
        <S.InputHint>*υποχρεωτικο</S.InputHint>
        <S.InputContainer>
          <S.TextInput
            value={message}
            onChange={onInputChangeSetState(setMessage)}
            name="message"
            type="text"
            placeholder="ΜΗΝΥΜΑ*"
            disabled={status !== 'pending'}
          />
          <S.TextInput
            value={name}
            onChange={onInputChangeSetState(setName)}
            name="name"
            type="text"
            placeholder="ΟΝΟΜΑ*"
            disabled={status !== 'pending'}
          />
          <S.TextInput
            value={email}
            onChange={onInputChangeSetState(setEmail)}
            name="email"
            type="email"
            placeholder="EMAIL*"
            disabled={status !== 'pending'}
          />
        </S.InputContainer>
        <S.ReCaptchaInfo>
          This site is protected by reCAPTCHA and the Google{' '}
          <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>{' '}
          and{' '}
          <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
            Terms of Service
          </a>{' '}
          apply.
        </S.ReCaptchaInfo>

        <S.ButtonContainer>
          {status === 'pending' && (
            <Button
              as="button"
              type="button"
              onClick={() => setStatus('loading') || submitForm(message, name, email, handleSubmitFinish)}
              disabled={message.length === 0 || name.lenght === 0 || email.length === 0}
            >
              Αποστολη
            </Button>
          )}
          {status === 'success' && (
            <S.SuccessContact>Ευχαριστούμε, θα επικοινωνήσουμε το συντομότερο δυνατόν μαζί σας.</S.SuccessContact>
          )}
          {status === 'error' && (
            <S.ErrorContact>Η αποστολή μηνύματος απέτυχε. Παρακαλούμε προσπαθήστε ξανα σε λίγο.</S.ErrorContact>
          )}
          {status === 'loading' && (
            <div>
              <FontIcon name="spin3" spin color={colors.main.blue} />
            </div>
          )}
        </S.ButtonContainer>
      </PageWrap>
    </Section>
  );
};

ContactSection.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
};

export default ContactSection;
