import styled from 'styled-components';

import { PageWrap } from 'src/modules/layout';
import { Heading } from 'src/modules/text';
import { breakpoints } from 'src/styles';

export default {
  PageWrap: styled(PageWrap)`
    padding: 0 20px;
  `,
  Heading: styled(Heading)`
    text-align: center;
    margin-bottom: 25px;
  `,
  LogoList: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media only screen and (min-width: ${breakpoints.xs.max}) {
      flex-direction: row;
    }
  `,
  LogoContainer: styled.div`
    margin: 25px;
  `,
};
