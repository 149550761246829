import styled from 'styled-components';

import { Heading } from 'src/modules/text';
import { layoutStyles } from 'src/modules/layout';
import { breakpoints } from 'src/styles';

export default {
  Content: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    ${layoutStyles.pageWrap};
    padding-top: 60px;
    padding-bottom: 40px;
    overflow: hidden;

    @media only screen and (min-width: ${breakpoints.xs.max}) {
      justify-content: space-between;
      padding-top: 120px;
      padding-bottom: 80px;
    }
  `,
  TextArea: styled.div`
    display: flex;
    flex-direction: column;
    max-width: 430px;

    @media only screen and (min-width: ${breakpoints.xs.max}) {
      justify-content: space-between;
      min-width: 430px;
    }
  `,
  Heading: styled(Heading)`
    font-size: 32px;
    margin-bottom: 20px;
  `,
  Text: styled.div`
    max-width: 380px;
  `,
  ImageArea: styled.div`
    display: none;
    width: 550px;
    min-width: 550px;
    margin-left: 50px;

    @media only screen and (min-width: ${breakpoints.xs.max}) {
      display: block;
    }
  `,
  ButtonContainer: styled.div`
    margin-top: 40px;
  `,
};
