import React from 'react';
import PropTypes from 'prop-types';

import { Layout } from 'src/modules/layout';

import HeaderSection from './index.HeaderSection';
import BulletSection from './index.BulletSection';
import ProgramSection from './index.ProgramSection';
import WhoSection from './index.WhoSection';
import ContactSection from './index.ContactSection';

const IndexPageTemplate = ({
  headerSectionData,
  bulletSectionData,
  programSectionData,
  whoSectionData,
  contactSectionData,
}) => (
  <Layout headOptions={{ title: 'Αρχική' }}>
    <HeaderSection {...headerSectionData} />
    <BulletSection {...bulletSectionData} />
    <ProgramSection {...programSectionData} />
    <WhoSection {...whoSectionData} />
    <ContactSection {...contactSectionData} />
  </Layout>
);

IndexPageTemplate.propTypes = {
  headerSectionData: PropTypes.shape(HeaderSection.propTypes).isRequired,
  bulletSectionData: PropTypes.shape(BulletSection.propTypes).isRequired,
  programSectionData: PropTypes.shape(ProgramSection.propTypes).isRequired,
  whoSectionData: PropTypes.shape(WhoSection.propTypes).isRequired,
  contactSectionData: PropTypes.shape(ContactSection.propTypes).isRequired,
};

export default IndexPageTemplate;
