import React from 'react';
import PropTypes from 'prop-types';

import { FluidImage } from 'src/modules/image';
import { convertMDToInnerHTML } from 'src/modules/text';
import { colors } from 'src/styles';
import { Section } from 'src/modules/layout';
import { Button, LinkButton } from 'src/modules/input';
import S from './index.HeaderSection.Styled';

let scroller = null;
if (typeof window !== `undefined`) {
  /* Fix for gatsby build */
  /* eslint-disable global-require */
  const SmoothScroll = require('smooth-scroll');
  scroller = new SmoothScroll();
}

const HeaderSection = ({ heading, text, image, link, isLinkContactAnchor }) => {
  return (
    <Section withPadding backgroundColor={colors.background.lightblue}>
      <S.Content>
        <S.TextArea>
          <S.Heading>{heading}</S.Heading>
          <S.Text dangerouslySetInnerHTML={convertMDToInnerHTML(text)}></S.Text>
          <S.ButtonContainer>
            {isLinkContactAnchor && (
              <Button onClick={() => scroller.animateScroll(document.querySelector('#contact'))}>με ενδιαφερει</Button>
            )}
            {!isLinkContactAnchor && <LinkButton to={link}>με ενδιαφερει</LinkButton>}
          </S.ButtonContainer>
        </S.TextArea>
        <S.ImageArea>
          <FluidImage image={image} />
        </S.ImageArea>
      </S.Content>
    </Section>
  );
};

HeaderSection.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.shape({
    childImageSharp: PropTypes.object,
  }).isRequired,
  link: PropTypes.string,
  isLinkContactAnchor: PropTypes.bool,
};

HeaderSection.defaultProps = {
  link: '',
  isLinkContactAnchor: false,
};

export default HeaderSection;
