import React from 'react';
import PropTypes from 'prop-types';

import { Section, PageWrap } from 'src/modules/layout';
import { colors } from 'src/styles';
import S from './index.ProgramSection.Styled';

const ProgramItem = ({ title, description }) => (
  <S.ProgramItem>
    <S.ProgramTitle>{title}</S.ProgramTitle>
    <S.ProgramDescription>{description}</S.ProgramDescription>
  </S.ProgramItem>
);

ProgramItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const ProgramsSection = ({ heading, subHeading, programs }) => {
  return (
    <Section withPadding backgroundColor={colors.background.lightblue}>
      <PageWrap>
        <S.HeadingContainer>
          <S.Heading>{heading}</S.Heading>
          <S.SubHeading>{subHeading}</S.SubHeading>
        </S.HeadingContainer>
        <S.ProgramList>
          {programs.map(program => (
            <ProgramItem key={program.title} title={program.title} description={program.description} />
          ))}
        </S.ProgramList>
      </PageWrap>
    </Section>
  );
};

ProgramsSection.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  programs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ProgramsSection;
